.wrapper-need {
	background-color: #FAFAFA;
}

.content-need {
	background-color: #FFFFFF;
	margin-top: 24px;
	margin-bottom: 24px;
	padding: 56px 80px;
}

.find-out-flag-container {
	padding: 16px 0;

	& > div {
		width: 100%;

		@media (min-width: 500px) {
			width: 50%;
		}

		@media (min-width: 992px) {
			width: 25%;
		}
	}
}

.find-out-flag {
	font-size: 14px;
	padding: 4px 0;
	color: #222;
	min-height: 44px;
    display: flex;
    align-items: center;
	justify-content: start;

	span {
		display: flex;
		width: calc(100% - 60px);
		margin-left: -7px;
		margin-top: -5px;
	}
}

#wrapper {
	position: relative;
	width: 100%;
}

body {
	font-size: 12px;
	line-height: 1.2;
	color: #94949d;
	font-weight: 400;
	background: #fdfdfd;
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
}

body.homepage,
body {
	font-family: 'Lato', sans-serif;
}

.contact-number {
	font-size: 14px;
	color: black;
	text-decoration: none;
	display: block;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: auto;
}

.contact-number:hover,
.contact-number:active {
	color: #052A5F;
}

.contact-number span {
	float: right;
}

input:focus,
textarea:focus,
.btn:focus,
.zoom img,
.img-thumb img,
.btn,
.btn:hover,
a,
a:hover *:hover {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

body #header {
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

body #header.fixed {
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
}

body #header.up {
	top: -162px;
	opacity: 0;
	visibility: hidden;
}

body #header.down {
	top: 0;
	opacity: 1;
	visibility: visible;
}

#header {
	position: fixed;
	z-index: 9999;
	top: 0px;
	left: 0px;
	right: 0;
	width: 100%;
	background: #ffffff;
}

#header.normal {
	position: relative;

	.container {
		padding-bottom: 0;
		position: relative;

		// .row {
		// 	display: flex;
		// 	align-items: center;
		// }
	}
}

#header .container {
	padding-bottom: 0;
}

#header .consultation {
	display: inline-block;
	text-align: right;
}

#header .consultation>a {
	font-size: 14px;
	color: #2b2a2f;
	text-transform: uppercase;
	font-family: 'Exo', sans-serif;
	font-weight: 600;
}

#header .consultation>a span {
	float: right;
	line-height: 40px;
	padding-left: 10px;
}


#header .logo {
	text-decoration: none;
	position: relative;
	margin: 10px 0;
	display: inline-flex;
	align-items: center;
}

.logo .svg-shape {
	left: 14px;
	position: absolute;
	top: -4px;
}

#header .nav {
	float: right;
}

#header .nav li a {
	font-size: 15px;
	color: #79797a;
	text-transform: capitalize;
	font-weight: 600;
	white-space: pre;
}

#header .navbar-default {
	background: none;
	border: none;
	margin-bottom: 0;

	.navbar-header button {
		margin-right: 0;
	}
}

#header .navbar-default .navbar-nav>.active>a,
#header .navbar-default .navbar-nav>.active>a:hover,
#header .navbar-default .navbar-nav>li a:hover,
#header .navbar-default .navbar-nav>.active>a:focus,
#header .nav>li:hover>a {
	transition: color .5s;
	color: #000;
}

#header .consultation {
	position: fixed;
	right: -250px;
	top: 25%;
	background: #e9e9ee;
	padding-left: 15px;
	transition: .4s right ease;
	padding-right: 10px;
}

#header .consultation:after {
	content: "";
	border-left: 10px solid transparent;
	border-top: 21px solid #e9e9ee;
	border-right: 10px solid #e9e9ee;
	border-bottom: 20px solid #e9e9ee;
	position: absolute;
	left: -20px;
	top: 0;
}

#header .consultation:hover:after {
	display: none;
}

#header .consultation:hover {
	right: 0;
	transition: .4s right ease;
}

body #header {
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

body #header.fixed {
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
}

body #header.up {
	top: -162px;
	opacity: 0;
	visibility: hidden;
}

body #header.down {
	top: 0;
	opacity: 1;
	visibility: visible;
}

.front-btn {
	width: 343px;
	height: 72px;
	max-width: 100%;
	background: #FFF;
	border-radius: 8px;
	-js-display: flex;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	align-items: center;
	box-sizing: border-box;
	position: relative;
	color: #fafbfd;
	font-size: 15px;
	font-weight: 700;
	text-transform: uppercase;
	padding: 25px 10px 25px 30px;
	transition: background .25s ease;
	display: flex;
	justify-content: space-between;
}

.front-btn::after {
	content: url('../img/arrow.svg');
	display: block;
	position: absolute;
	right: 9px;
}

.front-btn .text {
	max-width: calc(100% - 20px);
}

.front-btn.btn-blue {
	background: #052A5F;
}

.front-btn.btn-blue:hover,
.front-btn.btn-blue:focus {
	background: rgba(51, 80, 117, 0.9);
	color: white;
	text-decoration: none;

}

.front-btn:nth-of-type(2) {
	margin-top: 25px;
}

.btn-cta {
	border: 1px solid #57a603;
	background: #00cc00;
}

.btn-cta:hover {
	background: rgba(0, 230, 0, .8);
	color: #FFFFFF;
	text-decoration: none;
}

.btn-cta:after {
	color: #FFFFFF;
}

/*
 4) Footer Section
 ----------------------------------------*/
#footer {
	width: 100%;
	background: #ffffff;
}

#footer .main-footer {
	padding-top: 45px;
	width: 100%;
}

#footer .main-footer p.para-description {
	font-size: 12px;
}

#footer .copy-right {
	padding: 20px 10px;
}

#footer .contact-number span {
	float: left;
	margin-left: 22px;
}

.contact-number div.number-placeholder {
        display: inline-block;
}

#footer .contact-number {
	margin-top: 12px;
	outline: none;
}

.logoText {
	padding-left: 5px;
	position: relative;
	color: #052A5F;
	font-weight: bold;
	font-style: normal;
	text-decoration: none;
}

footer .spacer-mobile .logoText {
	padding-left: 10px;
	font-size: 24px;
	color: #052A5F;
	font-weight: bold;
}

#footer .main-footer {
	padding-top: 52px;
}

#footer .main-footer .para-description {
	margin-bottom: 8px;
	width: 78%;
}


#footer .contact-number span {
	margin-left: 13px;
	margin-top: 10px;
}

#footer .contact-number {
	margin-top: 15px;
}



#footer form {
	margin-top: 33px;
}

#footer .footer-form {
	position: relative;
}

#footer .footer-form .field {
	position: relative;
}

#footer .footer-form .field label {
	position: absolute;
	left: 11px;
	top: 15px;
	font-size: 14px;
	font-weight: 600;
	color: #79797a;
}

#footer .footer-info-text {
	width: 100%;
	height: 44px;
	font-size: 14px;
	font-weight: 600;
	color: #79797a;
	border: 1px solid #e5e3ec;
	margin-bottom: 16px;
	padding: 10px;
}

#footer .area-block {
	min-height: 100px;
	resize: none;
	padding-top: 13px;
}

#footer .send-request {
	color: #ffffff;
	font-weight: 700;
	line-height: 48px;
	margin-top: 4px;
	min-height: 48px;
	min-width: 170px;
	text-align: center;
	text-transform: uppercase;
	border: 0;
	background: #052A5F;
	font-size: 14px;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

#footer .copy-right {
	text-align: center;
	line-height: 1.3;
	font-size: 14px;
	color: #79797a;
	font-weight: 600;
	display: block;
	background-color: #f3f3f3;
}

#footer .copy-right a {
	color: #333;
}

#footer .copy-right a:hover {
	text-decoration: none;
}

.p-text {
	padding-bottom: 10px;
	font-size: 16px !important;
	color: #fff;
	display: block;
	font-family: 'Merriweather', serif;
	white-space: normal;
	width: 460px;
	line-height: 1.5 !important;
}

.homepage-3 .client {
	position: relative;
	width: 100%;
	padding: 56px 0;
	background: #ffffff;
}

.visa-types {
	padding: 56px 0;
	background-color: #FAFAFA;
}

.visa-types .visa-types-desc {
	padding: 0px 15px;
	float: left;
	width: calc(100% - 450px);
}

.visa-types .visa-types-desc p {
	font-family: 'Open Sans', sans-serif;
	font-size: 18px;
	letter-spacing: 0;
	color: #223750;
}

.visa-types .visa-types-img {
	float: right;
	width: 350px;
}

.visa-types .visa-types-img img {
	width: 350px;
	height: auto;
	max-width: 100%;
}

.payment-label {
	height: 45px;
}

.heading-details {
	font-size: 15px;
	color: #79797a;
	font-family: 'Open Sans', sans-serif;
	line-height: 1.4;
	display: block;
}

.our-principles {
	padding-top: 60px;
	background: #f3f3f3;
	padding-bottom: 40px;
}

.our-principles .index-box {
	width: 69px;
	height: 60px;
	border: 2px solid #2b2a2f;
	float: left;
	text-align: center;
	padding-top: 15px;
	margin-right: 28px;
	position: relative;
}

.our-principles .index-box span {
	color: #ee3135;
	font-size: 24px;
	font-weight: 700;
}

.our-principles .index-box:after {
	margin-top: -10px;
	position: absolute;
	right: -17px;
	top: 50%;
	border-left: 10px solid #f5f5f6;
	border-right: 10px solid transparent;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	content: "";
	display: block;
}

.our-principles .index-box:before {
	margin-top: -10px;
	position: absolute;
	right: -20px;
	top: 50%;
	border-left: 10px solid #2b2a2f;
	border-right: 10px solid transparent;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	content: "";
	display: block;
}

.our-principles .principles-box {
	margin-top: 25px;
}

.our-principles .principles-detail {
	float: left;
	width: 70%;
}

.contact-form {
	background: #f5f5f6;
	width: 100%;
	position: relative;
	padding-top: 90px;
	min-height: 660px;
	padding-bottom: 50px;
}

.contact-form .underline-label:after {
	/*bottom: 0;*/
	bottom: -10px;
}

.contact-form .container {
	position: relative;
}

#success {
	display: none;
	left: 15px;
	position: absolute;
	top: -48px;
}

.underline-label {
	position: relative;
	margin-bottom: 20px;
}

.underline-label:after {
	width: 27px;
	height: 2px;
	content: "";
	position: absolute;
	bottom: -10px;
	left: 0;
	display: block;
	background: #b11f24;
}

.logo {
	font-size: 20px;
	font-weight: 800;
	color: black;
	font-family: "Exo";
}

.thx {
	background-color: #118a74;
	color: white;
	padding-top: 110px;
	z-index: 999;
	font-size: 18px;
	padding-left: 5%;
	padding-right: 5%;
	padding-bottom: 50px;
}

.top-img {
	width: 100%;
	height: 450px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;

	.top-background {
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}
}

.buttons {
	width: 1170px;
	display: flex;
	flex-direction: column;
	a {
		text-transform: uppercase;
	}
}

.top-img {
	width: 100%;
	height: 450px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.buttons {
	width: 1170px;
	display: flex;
	flex-direction: column;
}

.page {
	color: #335075;
	.container {
		overflow: visible;
	}
	.page-wrapper {
		background: #fafafa;
		box-shadow: 0 2px 4px 0 rgba(34,36,38,.12), 0 2px 10px 0 rgba(34,36,38,.15);
		padding-right: 5%;
		padding-left: 5%;
		margin-top: 20px;
		margin-bottom: 35px;
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.heading {
		font-size: 24px;
		font-weight: 700;
		margin: 0 0 20px;
	}
	p {
		font-size: 18px;
		font-weight: 400;
		margin: 0;
	}
	&.page-content {
		p {
			font-size: 16px;
		}
	}
}

.expandable-navbar {
	padding-left: 15px;
	padding-right: 15px;
	transition: height 350ms ease-in-out;
	height: 0;
	overflow-y: hidden;

	@media (min-width: 768px) {
		height: auto;
		overflow: visible;
		margin-top: 0;
		position: absolute;
		top: 6px;
		left: 190px;
	}
}

.navbar-toggle {
	margin-top: -8px;
}

.callus {
	position: absolute;
	top: 0;
	right: 0;
}