
/*
 Table Of Contents
 1) General Text Formatting and Typography
 2) Site Wide Content
 3) Header Section
 4) Footer Section
 ===============================================*/
/*
 1) General Text Formatting and Typography
 ----------------------------------------*/
 h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	color: #22272e;
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	margin-top: 0px;
	margin-bottom: 0px;
	line-height: 1.2;
}
h1, .h1 {
	font-size: 50px;
	line-height: 0.9;
}
h2, .h2 {
	font-size: 26px;
        font-family: 'Open Sans', sans-serif;
	font-weight: 700;
        color: #0C2A50;
        margin-bottom: 30px;
}
h3, .h3 {
	font-size: 24px;
        font-family: 'Open Sans', sans-serif;
	font-weight: 700;
        color: #00154D;
        padding-top: 8px;
        margin-bottom: 14px;
}
h4, .h4 {
	font-size: 18px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
        color: #00154D;
        margin-bottom: 8px;
}
h5, .h5 {
	font-size: 13px;
	font-weight: 600;
	color: #2b2a2f;
	text-transform: uppercase;
}
h6, .h6 {
}
.row {
        margin-left: 0px;
        margin-right: 0px;
}
p, li, .alert {
        font-size: 16px;
        color: #00154D;
	line-height: 1.8;
}
p {
        margin-bottom: 30px;
}
ul {
        margin-bottom: 18px;
        padding-left: 14px;
}
ul.ul-lg li {
	line-height: 2.2;
}
.alert {
        padding: 20px 40px;
}
#formContactAlert {
	padding: 20px;
	&.alert-info {
		display: flex;
		align-items: center;
		.successIcon {
			background-image: url('./../img/success.svg');
			background-repeat: no-repeat;
			background-size: 30px;
			width: 30px;
			height: 30px;
			margin-right: 10px;
		}
	}
}
.container {
        padding-bottom: 30px;
}
img {
	max-width: 100%;
	height: auto;
}
a {
	outline: none !important;
}
input, button, textarea, select, checkbox, radio {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
}

.holiday-text {
	background: red;
	color: #fff;
	font-size: 16px;
    .container {
        padding: 10px;
    }
}