@media only screen and (max-width: 767px) {
    .content-need {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px 10px;
    }
}

@media only screen and (min-width: 768px) {
    .navbar-nav {
        display: inline-flex;
    }

    #header .contact-number {
        position: absolute;
    }

    .contact-number {
        display: block;
        right: 0;
        top: 24px;
    }

    /*Dropdown menu*/
    .navbar-default .navbar-nav>.open>a,
    .navbar-default .navbar-nav>.open>a:focus {
        background-color: transparent;
    }

    /*Dropdown menu*/
    .navbar-nav>li * {
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .navbar-default .navbar-nav>.open>a {
        background-color: transparent;
    }

    .dropdown-menu {
        display: none;
        opacity: 0;
    }

    .open>.dropdown-menu {
        display: none;
        opacity: 0;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .navbar-nav>li:hover .dropdown-menu {
        opacity: 0;
        background-color: #052A5F;
        display: block;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .navbar-nav>li {
        padding: 10px 0;
        -webkit-transition: background 0.2s ease-in-out;
        -moz-transition: background 0.2s ease-in-out;
        -ms-transition: background 0.2s ease-in-out;
        -o-transition: background 0.2s ease-in-out;
        transition: background 0.2s ease-in-out;
    }

    .dropdown-menu {
        margin-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    #header .nav li .dropdown-menu a {
        font-weight: 400;
    }

    #header .nav li .dropdown-menu li {
        padding: 5px 0;
    }

    .navbar-nav>li>.dropdown-menu {
        min-width: 215px;
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: #2a2627;
        border: none;
        border-radius: 0;
        -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        border-bottom: 2px solid #ee3135;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }
}

@media only screen and (min-width: 767px) {

    #wrapper.boxed,
    .boxed #header {
        margin: 0 auto;
        max-width: 768px;
        width: 100%;
    }

}

@media only screen and (max-width: 767px) {
    #header .consultation {
        display: none;
    }

    .homepage-3 #header .consultation {
        right: -200px;
    }

    #header .media-header {
        display: none;
    }

    #header .primary-right-block {
        float: none;
        text-align: left;
    }

    #header .primary-header {
        padding-bottom: 0;
        display: none;
    }

    #header .consultation>a {
        font-size: 12px;
    }

    #header .contact-number {
        display: none;
    }

    #header .navigation {
        position: absolute;
        margin-top: -50px;
        padding: 0;
        margin-left: auto;
        top: 16px;
        right: 20px;
        margin-top: 18px;
    }

    #header .navbar-default {
        margin-bottom: 0;
    }

    #header .navbar-nav {
        background: #ddd;
        margin-top: 0;
    }

    #header .nav {
        float: none;
        padding: 10px 0;
    }

    #header .logo {
        margin-top: 12px;
        z-index: 999;
    }

    .para-description {
        font-size: 12px;
        margin-bottom: 12px;
        margin-top: 8px;
    }

    .spacer-mobile {
        margin-bottom: 30px;
    }

    .client {
        padding: 30px 0 30px;
    }

    #footer .top-footer {
        display: none;
    }

    #footer .main-footer {
        padding-bottom: 40px;
        padding-top: 30px;
    }

    .our-principles {
        padding-top: 45px;
        padding-bottom: 40px;
    }

    .attorney-mob {
        margin-top: 20px;
    }

    .heading-details {
        line-height: 1.5;
        margin-top: 10px;
    }

    .our-principles .principles-detail {
        width: 65%;
    }

    .our-services {
        margin-top: 20px;
    }

    #slider .about-banner h1 {
        font-size: 35px;
    }

    .contact-form {
        padding-top: 45px;
        min-height: 520px;
    }
}

@media only screen and (min-width: 992px) {
    .top-phone-link {
        display: none;
    }
}

@media (min-width: 1200px) {

    #wrapper.boxed,
    .boxed #header {
        max-width: 1170px;
        width: 100%;
    }

}

@media screen and (max-width: 992px) {
    .contact-number {
        display: none;
    }

    .callus {
        margin-left: auto;
    }
}

@media screen and (max-width: 767px) {
    .contact-number {
        display: none;
    }

    .top-phone-link {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 30px;
        right: 80px;
    }
}

@media screen and (max-width: 767px) {
    .contact-number {
        display: none;
    }

    .top-phone-link {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 28px;
        right: 95px;
        left: auto;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact-number {
        display: block;
    }

    #wrapper.boxed,
    .boxed #header {
        margin: 0 auto;
        max-width: 970px;
        width: 100%;
    }

    #wrapper.boxed {
        max-width: 970px;
        width: 100%;
    }

    .nav>li>a {
        padding-left: 6px;
        padding-right: 6px;
    }

    #footer .icon-location {
        width: 36px;
    }

    #footer .contact-number span {

        margin-left: 17px;
    }

    #footer .main-footer p.para-description {
        margin-bottom: 5px;
    }

    .our-principles .principles-detail {
        width: 65%;
    }

    .our-services .heading-details {
        line-height: 1.5;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    #wrapper.boxed,
    .boxed #header {
        max-width: 750px;
        width: 100%;
    }

    .contact-number {
        display: none;
    }

    .p-text {
        width: 431px;
    }

    #slider .banner-text {
        left: 0;
    }

    #footer .nav {
        margin-top: 8px;
    }

    .logo .svg-shape {
        top: -8px;
    }

    .logo svg {
        width: 16px;
    }

    #header .contact-number {
        display: none;
    }

    #header .navbar-nav>li>a {
        padding: 15px 4px 15px 4px;
    }

    #header .navbar-nav>li>.dropdown-menu {
        min-width: 156px;
    }

    #footer .navbar-collapse {
        padding: 0;
    }

    #footer .navbar-nav>li>a {
        padding: 10px 7px 10px 10px;
        font-size: 13px;
    }

    #footer .consultation>a {
        font-size: 9px;
    }

    #footer .top-footer .icon-enevlope {
        background-position: -7px 0;
        width: 30px;
    }

    #footer .top-footer .consultation {
        float: left;
    }

    #footer .tablet-mode {
        margin-top: 40px;
    }

    .heading-details {
        line-height: 20px;
        margin-top: 10px;
    }

    .our-principles .index-box {
        height: 45px;
        margin-right: 7%;
        padding-top: 8px;
        width: 45px;
    }

    .our-principles .principles-detail {
        width: 72%;
    }
}

@media only screen and (max-width: 767px) {
    .p-text {
        width: 431px;
    }
}

@media only screen and (max-width: 480px) {
    .front-btn {
        width: calc(100% - 40px);
        font-size: 14px;
    }
}

@media only screen and (max-width: 991px) {
    .visa-types {
        padding: 20px 0;
    }

    .visa-types .visa-types-desc {
        float: none;
        width: 100%;
    }

    .visa-types .visa-types-img {
        float: none;
        text-align: center;
        width: 100%;
        margin-left: unset;
        margin-right: unset;
    }
}

@media only screen and (max-width: 1199px) {
    .buttons {
        width: 970px;
    }
}

@media only screen and (max-width: 992px) {
    .buttons {
        width: 100%;
        margin: 0;
        justify-content: center;
        margin-left: 4%;
    }
}

@media only screen and (max-width: 1199px) {
    .buttons {
        width: 970px;
    }
}

@media only screen and (max-width: 992px) {
    .buttons {
        width: 100%;
        margin: 0;
        justify-content: center;
        margin-left: 4%;
    }
}

@media screen and (max-width:369px) {
    .logoText {
        font-size: 15px;
    }
}

@media (min-width: 768px) {
    .navigation {
        display: none;
        align-items: center;
        padding-left: 0;
        margin-left: -30px;
    }

    .callus {
        top: 24px;
        right: 20px;
    }
}

@media (min-width: 992px) {
    .navigation {
        margin-top: 6px;
    }

    .contact-number span {
        margin-top: 6px;
    }
}