@font-face {
	font-family: 'Exo';
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: local('Exo Light Italic'), local('Exo-LightItalic'), url(https://fonts.gstatic.com/s/exo/v5/FO--mEKyXT4ugIkHKUxgOvesZW2xOQ-xsNqO47m55DA.ttf) format('truetype');
}

@font-face {
	font-family: 'Exo';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: local('Exo Italic'), local('Exo-Italic'), url(https://fonts.gstatic.com/s/exo/v5/ouqjB58-qIbIc6tNtOafrA.ttf) format('truetype');
}

@font-face {
	font-family: 'Exo';
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: local('Exo Medium Italic'), local('Exo-MediumItalic'), url(https://fonts.gstatic.com/s/exo/v5/EirSuhHc5LPsqOkwuKdd8PesZW2xOQ-xsNqO47m55DA.ttf) format('truetype');
}

@font-face {
	font-family: 'Exo';
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: local('Exo SemiBold Italic'), local('Exo-SemiBoldItalic'), url(https://fonts.gstatic.com/s/exo/v5/Ek_R6ngmT59El5kpkoxOePesZW2xOQ-xsNqO47m55DA.ttf) format('truetype');
}

@font-face {
	font-family: 'Exo';
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: local('Exo Bold Italic'), local('Exo-BoldItalic'), url(https://fonts.gstatic.com/s/exo/v5/SCJ8lcLktXFnX90KWp_oHPesZW2xOQ-xsNqO47m55DA.ttf) format('truetype');
}

@font-face {
	font-family: 'Exo';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: local('Exo Light'), local('Exo-Light'), url(https://fonts.gstatic.com/s/exo/v5/dmD6gKcs59jrq1uKvTTMxQ.ttf) format('truetype');
}

@font-face {
	font-family: 'Exo';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Exo Regular'), local('Exo-Regular'), url(https://fonts.gstatic.com/s/exo/v5/m5W5Curk5POZ4iQOoRn-Ug.ttf) format('truetype');
}

@font-face {
	font-family: 'Exo';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: local('Exo Medium'), local('Exo-Medium'), url(https://fonts.gstatic.com/s/exo/v5/9GQrDsQoLNPSbu4a8XkaZw.ttf) format('truetype');
}

@font-face {
	font-family: 'Exo';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: local('Exo SemiBold'), local('Exo-SemiBold'), url(https://fonts.gstatic.com/s/exo/v5/BfPy-oLZyJitfovLhnoy1A.ttf) format('truetype');
}

@font-face {
	font-family: 'Exo';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local('Exo Bold'), local('Exo-Bold'), url(https://fonts.gstatic.com/s/exo/v5/Pb-Tqi3xXEdTJ3ZTpmCsBw.ttf) format('truetype');
}

@font-face {
	font-family: 'Merriweather';
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: local('Merriweather Light Italic'), local('Merriweather-LightItalic'), url(https://fonts.gstatic.com/s/merriweather/v17/EYh7Vl4ywhowqULgRdYwIH3uxQAI1Bvm-GxtFNy4yl0.ttf) format('truetype');
}

@font-face {
	font-family: 'Merriweather';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: local('Merriweather Italic'), local('Merriweather-Italic'), url(https://fonts.gstatic.com/s/merriweather/v17/So5lHxHT37p2SS4-t60SlCtfYakCkPqOMDce0h_3gD8.ttf) format('truetype');
}

@font-face {
	font-family: 'Merriweather';
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: local('Merriweather Bold Italic'), local('Merriweather-BoldItalic'), url(https://fonts.gstatic.com/s/merriweather/v17/EYh7Vl4ywhowqULgRdYwICxQL91WRy8t8mPvAX_dIgA.ttf) format('truetype');
}

@font-face {
	font-family: 'Merriweather';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: local('Merriweather Light'), local('Merriweather-Light'), url(https://fonts.gstatic.com/s/merriweather/v17/ZvcMqxEwPfh2qDWBPxn6ngV_pQ1T3xN3K1c3sB361us.ttf) format('truetype');
}

@font-face {
	font-family: 'Merriweather';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Merriweather'), local('Merriweather-Regular'), url(https://fonts.gstatic.com/s/merriweather/v17/RFda8w1V0eDZheqfcyQ4EInF5uFdDttMLvmWuJdhhgs.ttf) format('truetype');
}

@font-face {
	font-family: 'Merriweather';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local('Merriweather Bold'), local('Merriweather-Bold'), url(https://fonts.gstatic.com/s/merriweather/v17/ZvcMqxEwPfh2qDWBPxn6nn06qf9KHRHwsVx7iw5MXmY.ttf) format('truetype');
}