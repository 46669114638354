.flag-sprite {
    display: inline-block;
    width: 60px;
    height: 40px;
    vertical-align: middle;
    margin-right: -15px;
    margin-left: 10px;
    margin-bottom: -16px;
    -moz-transform: scale(0.5);
    -moz-transform-origin: 0 0;
    -webkit-transform: scale(0.5);
    -webkit-transform-origin: 0 0;
    transform: scale(0.5);
    transform-origin: 0 0;
}

.flag-sprite-abkhazia {
    background: url('../img/flags-sprite.webp') -70px -3px;
}
.flag-sprite-afghanistan {
    background: url('../img/flags-sprite.webp') -3px -50px;
}
.flag-sprite-albania {
    background: url('../img/flags-sprite.webp') -69px -50px;
}
.flag-sprite-algeria {
    background: url('../img/flags-sprite.webp') -136px -3px;
}
.flag-sprite-american_samoa {
    background: url('../img/flags-sprite.webp') -136px -49px;
}
.flag-sprite-andorra {
    background: url('../img/flags-sprite.webp') -3px -96px;
}
.flag-sprite-angola {
    background: url('../img/flags-sprite.webp') -69px -96px;
}
.flag-sprite-anguilla {
    background: url('../img/flags-sprite.webp') -135px -96px;
}
.flag-sprite-antigua_and_barbuda {
    background: url('../img/flags-sprite.webp') -3px -142px;
}
.flag-sprite-argentina {
    background: url('../img/flags-sprite.webp') -69px -142px;
}
.flag-sprite-armenia {
    background: url('../img/flags-sprite.webp') -135px -142px;
}
.flag-sprite-aruba {
    background: url('../img/flags-sprite.webp') -202px -3px;
}
.flag-sprite-austral_islands {
    background: url('../img/flags-sprite.webp') -202px -49px;
}
.flag-sprite-australia {
    background: url('../img/flags-sprite.webp') -202px -95px;
}
.flag-sprite-austria {
    background: url('../img/flags-sprite.webp') -202px -141px;
}
.flag-sprite-azerbaijan {
    background: url('../img/flags-sprite.webp') -3px -188px;
}
.flag-sprite-bahamas {
    background: url('../img/flags-sprite.webp') -69px -188px;
}
.flag-sprite-bahrain {
    background: url('../img/flags-sprite.webp') -135px -188px;
}
.flag-sprite-bangladesh {
    background: url('../img/flags-sprite.webp') -201px -188px;
}
.flag-sprite-barbados {
    background: url('../img/flags-sprite.webp') -268px -3px;
}
.flag-sprite-belarus {
    background: url('../img/flags-sprite.webp') -268px -49px;
}
.flag-sprite-belgium {
    background: url('../img/flags-sprite.webp') -268px -95px;
}
.flag-sprite-belize {
    background: url('../img/flags-sprite.webp') -268px -141px;
}
.flag-sprite-benin {
    background: url('../img/flags-sprite.webp') -268px -187px;
}
.flag-sprite-bermuda {
    background: url('../img/flags-sprite.webp') -3px -234px;
}
.flag-sprite-bhutan {
    background: url('../img/flags-sprite.webp') -69px -234px;
}
.flag-sprite-bolivia {
    background: url('../img/flags-sprite.webp') -135px -234px;
}
.flag-sprite-bonaire {
    background: url('../img/flags-sprite.webp') -201px -234px;
}
.flag-sprite-bosnia_and_herzegovina {
    background: url('../img/flags-sprite.webp') -267px -234px;
}
.flag-sprite-botswana {
    background: url('../img/flags-sprite.webp') -3px -280px;
}
.flag-sprite-brazil {
    background: url('../img/flags-sprite.webp') -69px -280px;
}
.flag-sprite-british_indian_ocean_territory {
    background: url('../img/flags-sprite.webp') -135px -280px;
}
.flag-sprite-british_virgin_islands {
    background: url('../img/flags-sprite.webp') -201px -280px;
}
.flag-sprite-brunei {
    background: url('../img/flags-sprite.webp') -267px -280px;
}
.flag-sprite-bulgaria {
    background: url('../img/flags-sprite.webp') -334px -3px;
}
.flag-sprite-burkina_faso {
    background: url('../img/flags-sprite.webp') -334px -49px;
}
.flag-sprite-burundi {
    background: url('../img/flags-sprite.webp') -334px -95px;
}
.flag-sprite-cambodia {
    background: url('../img/flags-sprite.webp') -334px -141px;
}
.flag-sprite-cameroon {
    background: url('../img/flags-sprite.webp') -334px -187px;
}
.flag-sprite-canada {
    background: url('../img/flags-sprite.webp') -334px -233px;
}
.flag-sprite-canary_islands {
    background: url('../img/flags-sprite.webp') -334px -279px;
}
.flag-sprite-cape_verde {
    background: url('../img/flags-sprite.webp') -3px -326px;
}
.flag-sprite-cayman_islands {
    background: url('../img/flags-sprite.webp') -69px -326px;
}
.flag-sprite-central_african_republic {
    background: url('../img/flags-sprite.webp') -135px -326px;
}
.flag-sprite-chad {
    background: url('../img/flags-sprite.webp') -201px -326px;
}
.flag-sprite-chatham_islands {
    background: url('../img/flags-sprite.webp') -267px -326px;
}
.flag-sprite-chile {
    background: url('../img/flags-sprite.webp') -333px -326px;
}
.flag-sprite-china_prc {
    background: url('../img/flags-sprite.webp') -400px -3px;
}
.flag-sprite-christmas_island {
    background: url('../img/flags-sprite.webp') -400px -49px;
}
.flag-sprite-chuuk {
    background: url('../img/flags-sprite.webp') -400px -95px;
}
.flag-sprite-cocos_islands {
    background: url('../img/flags-sprite.webp') -400px -141px;
}
.flag-sprite-colombia {
    background: url('../img/flags-sprite.webp') -400px -187px;
}
.flag-sprite-comoros {
    background: url('../img/flags-sprite.webp') -400px -233px;
}
.flag-sprite-cook_islands {
    background: url('../img/flags-sprite.webp') -400px -279px;
}
.flag-sprite-costa_rica {
    background: url('../img/flags-sprite.webp') -400px -325px;
}
.flag-sprite-croatia {
    background: url('../img/flags-sprite.webp') -3px -372px;
}
.flag-sprite-cuba {
    background: url('../img/flags-sprite.webp') -69px -372px;
}
.flag-sprite-curacao {
    background: url('../img/flags-sprite.webp') -135px -372px;
}
.flag-sprite-cyprus {
    background: url('../img/flags-sprite.webp') -201px -372px;
}
.flag-sprite-czech_republic {
    background: url('../img/flags-sprite.webp') -267px -372px;
}
.flag-sprite-dem_rep_of_the_congo {
    background: url('../img/flags-sprite.webp') -333px -372px;
}
.flag-sprite-denmark {
    background: url('../img/flags-sprite.webp') -399px -372px;
}
.flag-sprite-djibouti {
    background: url('../img/flags-sprite.webp') -3px -418px;
}
.flag-sprite-dominica {
    background: url('../img/flags-sprite.webp') -69px -418px;
}
.flag-sprite-dominican_republic {
    background: url('../img/flags-sprite.webp') -135px -418px;
}
.flag-sprite-east_timor_timor_leste {
    background: url('../img/flags-sprite.webp') -201px -418px;
}
.flag-sprite-east_timor {
    background: url('../img/flags-sprite.webp') -267px -418px;
}
.flag-sprite-easter_island {
    background: url('../img/flags-sprite.webp') -333px -418px;
}
.flag-sprite-ecuador {
    background: url('../img/flags-sprite.webp') -399px -418px;
}
.flag-sprite-egypt {
    background: url('../img/flags-sprite.webp') -466px -3px;
}
.flag-sprite-el_salvador {
    background: url('../img/flags-sprite.webp') -466px -49px;
}
.flag-sprite-england {
    background: url('../img/flags-sprite.webp') -466px -95px;
}
.flag-sprite-equatorial_guinea {
    background: url('../img/flags-sprite.webp') -3px -3px;
}
.flag-sprite-eritrea {
    background: url('../img/flags-sprite.webp') -466px -141px;
}
.flag-sprite-estonia {
    background: url('../img/flags-sprite.webp') -466px -187px;
}
.flag-sprite-ethiopia {
    background: url('../img/flags-sprite.webp') -466px -233px;
}
.flag-sprite-falkland_islands {
    background: url('../img/flags-sprite.webp') -466px -279px;
}
.flag-sprite-fed_states_of_micronesia {
    background: url('../img/flags-sprite.webp') -466px -325px;
}
.flag-sprite-fiji {
    background: url('../img/flags-sprite.webp') -466px -371px;
}
.flag-sprite-finland {
    background: url('../img/flags-sprite.webp') -466px -417px;
}
.flag-sprite-france {
    background: url('../img/flags-sprite.webp') -3px -464px;
}
.flag-sprite-french_guiana {
    background: url('../img/flags-sprite.webp') -69px -464px;
}
.flag-sprite-french_polynesia {
    background: url('../img/flags-sprite.webp') -135px -464px;
}
.flag-sprite-gabon {
    background: url('../img/flags-sprite.webp') -201px -464px;
}
.flag-sprite-gambia {
    background: url('../img/flags-sprite.webp') -267px -464px;
}
.flag-sprite-gambier_islands {
    background: url('../img/flags-sprite.webp') -333px -464px;
}
.flag-sprite-georgia {
    background: url('../img/flags-sprite.webp') -399px -464px;
}
.flag-sprite-germany {
    background: url('../img/flags-sprite.webp') -465px -464px;
}
.flag-sprite-ghana {
    background: url('../img/flags-sprite.webp') -532px -3px;
}
.flag-sprite-greece {
    background: url('../img/flags-sprite.webp') -532px -49px;
}
.flag-sprite-greenland {
    background: url('../img/flags-sprite.webp') -532px -95px;
}
.flag-sprite-grenada {
    background: url('../img/flags-sprite.webp') -532px -141px;
}
.flag-sprite-guadeloupe {
    background: url('../img/flags-sprite.webp') -532px -187px;
}
.flag-sprite-guam {
    background: url('../img/flags-sprite.webp') -532px -233px;
}
.flag-sprite-guatemala {
    background: url('../img/flags-sprite.webp') -532px -279px;
}
.flag-sprite-guinea {
    background: url('../img/flags-sprite.webp') -532px -325px;
}
.flag-sprite-guinea_bissau {
    background: url('../img/flags-sprite.webp') -532px -371px;
}
.flag-sprite-guyana {
    background: url('../img/flags-sprite.webp') -532px -417px;
}
.flag-sprite-haiti {
    background: url('../img/flags-sprite.webp') -532px -463px;
}
.flag-sprite-hawaii {
    background: url('../img/flags-sprite.webp') -3px -510px;
}
.flag-sprite-honduras {
    background: url('../img/flags-sprite.webp') -69px -510px;
}
.flag-sprite-hong_kong {
    background: url('../img/flags-sprite.webp') -135px -510px;
}
.flag-sprite-hungary {
    background: url('../img/flags-sprite.webp') -201px -510px;
}
.flag-sprite-iceland {
    background: url('../img/flags-sprite.webp') -267px -510px;
}
.flag-sprite-india {
    background: url('../img/flags-sprite.webp') -333px -510px;
}
.flag-sprite-indonesia {
    background: url('../img/flags-sprite.webp') -399px -510px;
}
.flag-sprite-iran {
    background: url('../img/flags-sprite.webp') -465px -510px;
}
.flag-sprite-iraq {
    background: url('../img/flags-sprite.webp') -531px -510px;
}
.flag-sprite-ireland {
    background: url('../img/flags-sprite.webp') -598px -3px;
}
.flag-sprite-israel {
    background: url('../img/flags-sprite.webp') -598px -49px;
}
.flag-sprite-italy {
    background: url('../img/flags-sprite.webp') -598px -95px;
}
.flag-sprite-ivory_coast {
    background: url('../img/flags-sprite.webp') -598px -141px;
}
.flag-sprite-jamaica {
    background: url('../img/flags-sprite.webp') -598px -187px;
}
.flag-sprite-japan {
    background: url('../img/flags-sprite.webp') -598px -233px;
}
.flag-sprite-johnston_atoll {
    background: url('../img/flags-sprite.webp') -598px -279px;
}
.flag-sprite-jordan {
    background: url('../img/flags-sprite.webp') -598px -325px;
}
.flag-sprite-kazakhstan {
    background: url('../img/flags-sprite.webp') -598px -371px;
}
.flag-sprite-kenya {
    background: url('../img/flags-sprite.webp') -598px -417px;
}
.flag-sprite-kiribati {
    background: url('../img/flags-sprite.webp') -598px -463px;
}
.flag-sprite-kosrae {
    background: url('../img/flags-sprite.webp') -598px -509px;
}
.flag-sprite-kuwait {
    background: url('../img/flags-sprite.webp') -3px -556px;
}
.flag-sprite-kyrgyzstan {
    background: url('../img/flags-sprite.webp') -69px -556px;
}
.flag-sprite-laos {
    background: url('../img/flags-sprite.webp') -135px -556px;
}
.flag-sprite-latvia {
    background: url('../img/flags-sprite.webp') -201px -556px;
}
.flag-sprite-lebanon {
    background: url('../img/flags-sprite.webp') -267px -556px;
}
.flag-sprite-lesotho {
    background: url('../img/flags-sprite.webp') -333px -556px;
}
.flag-sprite-liberia {
    background: url('../img/flags-sprite.webp') -399px -556px;
}
.flag-sprite-libya {
    background: url('../img/flags-sprite.webp') -465px -556px;
}
.flag-sprite-liechtenstein {
    background: url('../img/flags-sprite.webp') -531px -556px;
}
.flag-sprite-lithuania {
    background: url('../img/flags-sprite.webp') -597px -556px;
}
.flag-sprite-luxembourg {
    background: url('../img/flags-sprite.webp') -3px -602px;
}
.flag-sprite-macau {
    background: url('../img/flags-sprite.webp') -69px -602px;
}
.flag-sprite-macedonia {
    background: url('../img/flags-sprite.webp') -135px -602px;
}
.flag-sprite-madagascar {
    background: url('../img/flags-sprite.webp') -201px -602px;
}
.flag-sprite-madeira {
    background: url('../img/flags-sprite.webp') -267px -602px;
}
.flag-sprite-malawi {
    background: url('../img/flags-sprite.webp') -333px -602px;
}
.flag-sprite-malaysia {
    background: url('../img/flags-sprite.webp') -399px -602px;
}
.flag-sprite-maldives {
    background: url('../img/flags-sprite.webp') -465px -602px;
}
.flag-sprite-mali {
    background: url('../img/flags-sprite.webp') -531px -602px;
}
.flag-sprite-malta {
    background: url('../img/flags-sprite.webp') -597px -602px;
}
.flag-sprite-marquesas_islands {
    background: url('../img/flags-sprite.webp') -664px -3px;
}
.flag-sprite-marshall_islands {
    background: url('../img/flags-sprite.webp') -664px -49px;
}
.flag-sprite-martinique {
    background: url('../img/flags-sprite.webp') -664px -95px;
}
.flag-sprite-mauritania {
    background: url('../img/flags-sprite.webp') -664px -141px;
}
.flag-sprite-mauritius {
    background: url('../img/flags-sprite.webp') -664px -187px;
}
.flag-sprite-mexico {
    background: url('../img/flags-sprite.webp') -664px -233px;
}
.flag-sprite-midway_islands {
    background: url('../img/flags-sprite.webp') -664px -279px;
}
.flag-sprite-moldova {
    background: url('../img/flags-sprite.webp') -664px -325px;
}
.flag-sprite-monaco {
    background: url('../img/flags-sprite.webp') -664px -371px;
}
.flag-sprite-mongolia {
    background: url('../img/flags-sprite.webp') -664px -417px;
}
.flag-sprite-montenegro {
    background: url('../img/flags-sprite.webp') -664px -463px;
}
.flag-sprite-montserrat {
    background: url('../img/flags-sprite.webp') -664px -509px;
}
.flag-sprite-morocco {
    background: url('../img/flags-sprite.webp') -664px -555px;
}
.flag-sprite-mozambique {
    background: url('../img/flags-sprite.webp') -664px -601px;
}
.flag-sprite-myanmar {
    background: url('../img/flags-sprite.webp') -3px -648px;
}
.flag-sprite-nagorno_karabakhs {
    background: url('../img/flags-sprite.webp') -69px -648px;
}
.flag-sprite-namibia {
    background: url('../img/flags-sprite.webp') -135px -648px;
}
.flag-sprite-nauru {
    background: url('../img/flags-sprite.webp') -201px -648px;
}
.flag-sprite-nepal {
    background: url('../img/flags-sprite.webp') -267px -648px;
}
.flag-sprite-netherlands {
    background: url('../img/flags-sprite.webp') -333px -648px;
}
.flag-sprite-new_caledonia {
    background: url('../img/flags-sprite.webp') -399px -648px;
}
.flag-sprite-new_zealand {
    background: url('../img/flags-sprite.webp') -465px -648px;
}
.flag-sprite-nicaragua {
    background: url('../img/flags-sprite.webp') -531px -648px;
}
.flag-sprite-niger {
    background: url('../img/flags-sprite.webp') -597px -648px;
}
.flag-sprite-nigeria {
    background: url('../img/flags-sprite.webp') -663px -648px;
}
.flag-sprite-niue {
    background: url('../img/flags-sprite.webp') -730px -3px;
}
.flag-sprite-norfolk_island {
    background: url('../img/flags-sprite.webp') -730px -49px;
}
.flag-sprite-north_korea {
    background: url('../img/flags-sprite.webp') -730px -95px;
}
.flag-sprite-northern_cyprus {
    background: url('../img/flags-sprite.webp') -730px -141px;
}
.flag-sprite-northern_ireland {
    background: url('../img/flags-sprite.webp') -730px -187px;
}
.flag-sprite-northern_mariana {
    background: url('../img/flags-sprite.webp') -730px -233px;
}
.flag-sprite-norway {
    background: url('../img/flags-sprite.webp') -730px -279px;
}
.flag-sprite-oman {
    background: url('../img/flags-sprite.webp') -730px -325px;
}
.flag-sprite-pakistan {
    background: url('../img/flags-sprite.webp') -730px -371px;
}
.flag-sprite-palau {
    background: url('../img/flags-sprite.webp') -730px -417px;
}
.flag-sprite-palestine {
    background: url('../img/flags-sprite.webp') -730px -463px;
}
.flag-sprite-palmyra_atoll {
    background: url('../img/flags-sprite.webp') -730px -509px;
}
.flag-sprite-panama {
    background: url('../img/flags-sprite.webp') -730px -555px;
}
.flag-sprite-papua_new_guinea {
    background: url('../img/flags-sprite.webp') -730px -601px;
}
.flag-sprite-paraguay {
    background: url('../img/flags-sprite.webp') -730px -647px;
}
.flag-sprite-peru {
    background: url('../img/flags-sprite.webp') -3px -694px;
}
.flag-sprite-philippines {
    background: url('../img/flags-sprite.webp') -69px -694px;
}
.flag-sprite-pitcairn_islands {
    background: url('../img/flags-sprite.webp') -135px -694px;
}
.flag-sprite-pohnpei {
    background: url('../img/flags-sprite.webp') -201px -694px;
}
.flag-sprite-poland {
    background: url('../img/flags-sprite.webp') -267px -694px;
}
.flag-sprite-portugal {
    background: url('../img/flags-sprite.webp') -333px -694px;
}
.flag-sprite-puerto_rico {
    background: url('../img/flags-sprite.webp') -399px -694px;
}
.flag-sprite-qatar {
    background: url('../img/flags-sprite.webp') -465px -694px;
}
.flag-sprite-republic_of_the_congo {
    background: url('../img/flags-sprite.webp') -531px -694px;
}
.flag-sprite-romania {
    background: url('../img/flags-sprite.webp') -597px -694px;
}
.flag-sprite-russia {
    background: url('../img/flags-sprite.webp') -663px -694px;
}
.flag-sprite-rwanda {
    background: url('../img/flags-sprite.webp') -729px -694px;
}
.flag-sprite-s_pierre_and_miquelon {
    background: url('../img/flags-sprite.webp') -3px -740px;
}
.flag-sprite-s_vincent_and_grenadines {
    background: url('../img/flags-sprite.webp') -69px -740px;
}
.flag-sprite-so_tomz_and_prncipe {
    background: url('../img/flags-sprite.webp') -135px -740px;
}
.flag-sprite-saba {
    background: url('../img/flags-sprite.webp') -201px -740px;
}
.flag-sprite-sahrawi_arab_dem_rep {
    background: url('../img/flags-sprite.webp') -267px -740px;
}
.flag-sprite-saint_barthelemy {
    background: url('../img/flags-sprite.webp') -333px -740px;
}
.flag-sprite-saint_kitts_and_nevis {
    background: url('../img/flags-sprite.webp') -399px -740px;
}
.flag-sprite-saint_lucia {
    background: url('../img/flags-sprite.webp') -465px -740px;
}
.flag-sprite-saint_martin {
    background: url('../img/flags-sprite.webp') -531px -740px;
}
.flag-sprite-samoa {
    background: url('../img/flags-sprite.webp') -597px -740px;
}
.flag-sprite-san_marino {
    background: url('../img/flags-sprite.webp') -663px -740px;
}
.flag-sprite-saudi_arabia {
    background: url('../img/flags-sprite.webp') -729px -740px;
}
.flag-sprite-scotland {
    background: url('../img/flags-sprite.webp') -796px -3px;
}
.flag-sprite-senegal {
    background: url('../img/flags-sprite.webp') -796px -49px;
}
.flag-sprite-serbia {
    background: url('../img/flags-sprite.webp') -796px -95px;
}
.flag-sprite-seychelles {
    background: url('../img/flags-sprite.webp') -796px -141px;
}
.flag-sprite-sierra_leone {
    background: url('../img/flags-sprite.webp') -796px -187px;
}
.flag-sprite-singapore {
    background: url('../img/flags-sprite.webp') -796px -233px;
}
.flag-sprite-sint_eustatius {
    background: url('../img/flags-sprite.webp') -796px -279px;
}
.flag-sprite-sint_maarten {
    background: url('../img/flags-sprite.webp') -796px -325px;
}
.flag-sprite-slovakia {
    background: url('../img/flags-sprite.webp') -796px -371px;
}
.flag-sprite-slovenia {
    background: url('../img/flags-sprite.webp') -796px -417px;
}
.flag-sprite-solomon_islands {
    background: url('../img/flags-sprite.webp') -796px -463px;
}
.flag-sprite-somalia {
    background: url('../img/flags-sprite.webp') -796px -509px;
}
.flag-sprite-somaliland {
    background: url('../img/flags-sprite.webp') -796px -555px;
}
.flag-sprite-south_africa {
    background: url('../img/flags-sprite.webp') -796px -601px;
}
.flag-sprite-south_georgia {
    background: url('../img/flags-sprite.webp') -796px -647px;
}
.flag-sprite-south_korea {
    background: url('../img/flags-sprite.webp') -796px -693px;
}
.flag-sprite-south_ossetia {
    background: url('../img/flags-sprite.webp') -796px -739px;
}
.flag-sprite-south_sudan {
    background: url('../img/flags-sprite.webp') -3px -786px;
}
.flag-sprite-spain {
    background: url('../img/flags-sprite.webp') -69px -786px;
}
.flag-sprite-sri_lanka {
    background: url('../img/flags-sprite.webp') -135px -786px;
}
.flag-sprite-sudan {
    background: url('../img/flags-sprite.webp') -201px -786px;
}
.flag-sprite-suriname {
    background: url('../img/flags-sprite.webp') -267px -786px;
}
.flag-sprite-swaziland {
    background: url('../img/flags-sprite.webp') -333px -786px;
}
.flag-sprite-sweden {
    background: url('../img/flags-sprite.webp') -399px -786px;
}
.flag-sprite-switzerland {
    background: url('../img/flags-sprite.webp') -465px -786px;
}
.flag-sprite-syria {
    background: url('../img/flags-sprite.webp') -531px -786px;
}
.flag-sprite-taiwan_roc {
    background: url('../img/flags-sprite.webp') -597px -786px;
}
.flag-sprite-tajikistan {
    background: url('../img/flags-sprite.webp') -663px -786px;
}
.flag-sprite-tanzania {
    background: url('../img/flags-sprite.webp') -729px -786px;
}
.flag-sprite-thailand {
    background: url('../img/flags-sprite.webp') -795px -786px;
}
.flag-sprite-togo {
    background: url('../img/flags-sprite.webp') -862px -3px;
}
.flag-sprite-tokelau_nz {
    background: url('../img/flags-sprite.webp') -862px -49px;
}
.flag-sprite-tonga {
    background: url('../img/flags-sprite.webp') -862px -95px;
}
.flag-sprite-trinidad_and_tobago {
    background: url('../img/flags-sprite.webp') -862px -141px;
}
.flag-sprite-tuamotu_archipelago {
    background: url('../img/flags-sprite.webp') -862px -187px;
}
.flag-sprite-tunisia {
    background: url('../img/flags-sprite.webp') -862px -233px;
}
.flag-sprite-turkey {
    background: url('../img/flags-sprite.webp') -862px -279px;
}
.flag-sprite-turkmenistan {
    background: url('../img/flags-sprite.webp') -862px -325px;
}
.flag-sprite-turks_and_caicos_islands {
    background: url('../img/flags-sprite.webp') -862px -371px;
}
.flag-sprite-tuvalu {
    background: url('../img/flags-sprite.webp') -862px -417px;
}
.flag-sprite-virgin_islands {
    background: url('../img/flags-sprite.webp') -862px -463px;
}
.flag-sprite-uganda {
    background: url('../img/flags-sprite.webp') -862px -509px;
}
.flag-sprite-ukraine {
    background: url('../img/flags-sprite.webp') -862px -555px;
}
.flag-sprite-united_arab_emirates {
    background: url('../img/flags-sprite.webp') -862px -601px;
}
.flag-sprite-united_kingdom {
    background: url('../img/flags-sprite.webp') -862px -647px;
}
.flag-sprite-united_states {
    background: url('../img/flags-sprite.webp') -862px -693px;
}
.flag-sprite-uruguay {
    background: url('../img/flags-sprite.webp') -862px -739px;
}
.flag-sprite-uzbekistan {
    background: url('../img/flags-sprite.webp') -862px -785px;
}
.flag-sprite-vanuatu {
    background: url('../img/flags-sprite.webp') -3px -832px;
}
.flag-sprite-vatican_city {
    background: url('../img/flags-sprite.webp') -69px -832px;
}
.flag-sprite-venezuela {
    background: url('../img/flags-sprite.webp') -135px -832px;
}
.flag-sprite-vietnam {
    background: url('../img/flags-sprite.webp') -201px -832px;
}
.flag-sprite-wake_island {
    background: url('../img/flags-sprite.webp') -267px -832px;
}
.flag-sprite-wales {
    background: url('../img/flags-sprite.webp') -333px -832px;
}
.flag-sprite-wallis_and_futuna {
    background: url('../img/flags-sprite.webp') -399px -832px;
}
.flag-sprite-yap {
    background: url('../img/flags-sprite.webp') -465px -832px;
}
.flag-sprite-yemen {
    background: url('../img/flags-sprite.webp') -531px -832px;
}
.flag-sprite-zambia {
    background: url('../img/flags-sprite.webp') -597px -832px;
}
.flag-sprite-zimbabwe {
    background: url('../img/flags-sprite.webp') -663px -832px;
}
